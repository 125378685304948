<template>
  <div class="large-data-screen">
    <!-- 头部的盒子 -->
    <div class="header">
      <h1 style="font-weight: normal">
        政策服务云平台 | 广东<b style="color: #fff">&bull;</b>佛山
      </h1>
      <div class="showTime">{{ time }}</div>
    </div>

    <!-- 页面主体部分 -->
    <section class="mainbox">
      <div class="column">
        <div class="panel bar">
          <h2>政策文件</h2>
          <div class="chart"></div>
          <div class="panel-footer"></div>
        </div>
        <div class="panel line">
          <h2>扶持项目</h2>
          <div class="chart">图表</div>
          <div class="panel-footer"></div>
        </div>
        <div class="panel pie">
          <div class="panel-pie" style="margin-top: 20px; display: flex">
            <div class="pie-one" style="width: 50%; height: 203px">
              <div class="chart">图表</div>
            </div>
            <div class="pie-two" style="width: 50%; height: 203px">
              <div class="chart">图表</div>
            </div>
          </div>
          <div class="panel-footer"></div>
        </div>
      </div>
      <div class="column">
        <div class="no">
          <div class="no-hd">
            <ul>
              <li>{{ datas.cumulativeNumberOfViews }}</li>
              <li>{{ datas.numberOfViewsToday }}</li>
            </ul>
          </div>
          <div class="no-bd">
            <ul>
              <li>累计浏览数量</li>
              <li>本日浏览数量</li>
            </ul>
          </div>
        </div>
        <div class="map">
          <div class="map1"></div>
          <div class="map2" style="display: none"></div>
          <div class="map3"></div>
          <div class="chart" style="width: 100%; height: 50.125rem;"></div>
        </div>
      </div>
      <div class="column">
        <div class="panel bar2">
          <h2>政策浏览数（按行业）</h2>
          <div class="chart">图表</div>
          <div class="panel-footer"></div>
        </div>
        <div class="panel line2">
          <h2>项目累计数（按区域）</h2>
          <div class="chart">图表</div>
          <div class="panel-footer"></div>
        </div>
        <div class="panel pie2">
          <h2>注册用户数</h2>
          <div class="chart">图表</div>
          <div class="panel-footer"></div>
        </div>
      </div>
    </section>
    <div class="footer" style="position: relative; top: -50px">
      广东政沣云计算有限公司版权所有
    </div>
  </div>
</template>

<script>
import { request } from '../../../network';
import bgImg from '../../../assets/pc/images/large_data_screen/bg.jpg';
import '../../../config/rem';
import {
  policyDocument,
  numOfPolicy,
  supportingPro,
  numOfProject,
  projectQuota,
  policyLevel,
  numOfUsers,
  chinaMap
} from '../../../plugins/echarts/map/createEcharts/index';
const cityCode = 440600;
export default {
  name: 'LargeDataScreen',
  data () {
    return {
      time: '',
      timer: null,
      timerTwo: null,
      datas: {},
      loading: null
    };
  },
  async mounted () {
    this.timer = setInterval(() => {
      this.getTime();
    }, 1000);
    this.createData();
    this.timerTwo = setInterval(() => {
      this.createData();
    }, 300000);
    this.setChinaMap();
    this.addBodyStyle();
  },
  beforeDestroy () {
    if (this.timer) clearTimeout(this.timer);
    if (this.timerTwo) clearTimeout(this.timerTwo);
    this.removeBodyStyle();
  },
  methods: {
    async createData () {
      this.getData().then(() => {
        this.policyDocument();
        this.numOfPolicy();
        this.supportingPro();
        this.numOfProject();
        this.projectQuota();
        this.policyLevel();
        this.numOfUsers();
      }).then(() => {
        this.loading.close();
      });
    },
    async getData () {
      this.loading = this.$loading({
        target: 'large-data-screen',
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const { data: { code, data } } = await request({
        methods: 'Get',
        url: '/statisticalAnalysis/exportPolicyOfStatistical',
        params: {
          cityCode: cityCode
        }
      });
      if (code === 200) {
        for (const key in data) {
          this.datas[key] = data[key];
        }
      } else {
        this.$message.error('获取数据失败');
      }
    },
    addBodyStyle () {
      const bodyStyle = document.body;
      bodyStyle.style.background = `url(${bgImg}) no-repeat`;
      bodyStyle.style.backgroundPosition = 'center center';
      bodyStyle.style.backgroundAttachment = 'fixed';
      bodyStyle.style.backgroundSize = 'cover';
      // bodyStyle.setAttribute('class', 'addStyle')
    },
    removeBodyStyle () {
      const bodyStyle = document.body;
      bodyStyle.style.background = '';
      bodyStyle.style.backgroundPosition = '';
      bodyStyle.style.backgroundAttachment = '';
      bodyStyle.style.backgroundSize = '';
      // bodyStyle.removeAttribute('class', 'addStyle')
    },
    changeData (data) {
      return data > 9 ? data : `0${data}`;
    },
    getTime () {
      const dt = new Date();
      const y = dt.getFullYear();
      const mt = this.changeData(dt.getMonth() + 1);
      const day = this.changeData(dt.getDate());
      const h = this.changeData(dt.getHours()); // 获取时
      const m = this.changeData(dt.getMinutes()); // 获取分
      const s = this.changeData(dt.getSeconds()); // 获取秒
      this.$nextTick(() => {
        this.time =
          y + '年' + mt + '月' + day + '日' + ' ' + h + ':' + m + ':' + s;
      });
    },
    // 政策文件
    policyDocument () {
      const xData = this.datas.zhengCe.map(item => item.name);
      const seriesVal = this.datas.zhengCe.map(item => item.monCount);
      policyDocument(this.$echarts, xData, seriesVal);
    },
    // 政策浏览数（按行业）
    numOfPolicy () {
      const legendData = this.datas.zhengCeBrowsee.map(item => item.name);
      const xData = this.datas.zhengCeBrowseDate;
      numOfPolicy(this.$echarts, legendData, xData, this.datas.zhengCeBrowsee);
    },
    // 扶持项目
    supportingPro () {
      const xData = this.datas.formal.map(item => item.name);
      const seriesVal = this.datas.formal.map(item => item.monCount);
      supportingPro(this.$echarts, xData, seriesVal);
    },
    // 项目累计数（按区域）
    numOfProject () {
      const xData = this.datas.sumFormalArea.map(item => item.name);
      const seriesVal = this.datas.sumFormalArea.map(item => item.sumcount);
      numOfProject(this.$echarts, xData, seriesVal);
    },
    // 项目额度分布(万元)
    projectQuota () {
      const seriesVal = this.datas.lines.map(item => {
        return { value: item.count, name: item.name };
      });
      projectQuota(this.$echarts, seriesVal);
    },
    // 政策级别分布
    policyLevel () {
      const seriesVal = this.datas.levelVo.map(item => {
        return { value: item.sumcount, name: item.level };
      });
      policyLevel(this.$echarts, seriesVal);
    },
    // 饼形图2 注册用户数
    numOfUsers () {
      const legendData = [ '注册数' ];
      const xData = this.datas.registeredUsers.map(item => item.name);
      const seriesVal = this.datas.registeredUsers.map(item => item.monCount);
      numOfUsers(this.$echarts, legendData, xData, seriesVal);
    },
    // 模拟飞行路线模块地图模块
    setChinaMap () {
      chinaMap(this.$echarts);
    }
  }
};
</script>

<style>
/* .addStyle {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #5bc0de;
  }
  ::-webkit-scrollbar-track {
    background-color: #ddd;
  }
} */
</style>
<style scoped lang="less">

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li {
  list-style: none;
}
/* 声明字体*/
@font-face {
  font-family: electronicFont;
  src: url(../../../assets/pc/font/DS-DIGIT.TTF);
}
.large-data-screen {
  line-height: 1.15rem;
  padding-top: .25rem;
  min-width: 1024px;
  max-width: 1920px;
}
.addBody {
  ::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    position: absolute !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #5bc0de !important;
  }
  ::-webkit-scrollbar-track {
    background-color: #ddd !important;
  }
}
.header {
  position: relative;
  height: 6.25rem;
  background: #000d4a url(../../../assets/pc/images/large_data_screen/head_bg.png) no-repeat;
  background-size: 100% 100%;
}
.header h1 {
  margin-top: 0.25rem;
  font-size: 2.4rem;
  color: #fff;
  text-align: center;
  line-height: 5.55rem;
}
.header .showTime {
  position: absolute;
  right: 0.375rem;
  top: 0;
  line-height: 5.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
}
.mainbox {
  display: flex;
  min-width: 1024px;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0.125rem 0.125rem 0;
}
.mainbox .column {
  /* flex: 3; */
  width: 30%;
}
.mainbox .column:nth-child(2) {
  /* flex: 3; */
  width: 40%;
  margin: 0 0.125rem 0.1875rem;
  overflow: hidden;
}
.mainbox .panel {
  position: relative;
  height: 20rem;
  padding: 0 0.8rem 0.5rem;
  border: 1px solid rgba(25, 186, 139, 0.17);
  margin-bottom: 1.1875rem;
  background: url(../../../assets/pc/images/large_data_screen/line.png) rgba(255, 255, 255, 0.03);
}
.mainbox .panel::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-left: 2px solid #02a6b5;
  border-top: 2px solid #02a6b5;
  content: "";
}
.mainbox .panel::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-right: 2px solid #02a6b5;
  border-top: 2px solid #02a6b5;
  content: "";
}
.mainbox .panel .panel-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.mainbox .panel .panel-footer::before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-left: 2px solid #02a6b5;
  border-bottom: 2px solid #02a6b5;
  content: "";
}
.mainbox .panel .panel-footer::after {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-right: 2px solid #02a6b5;
  border-bottom: 2px solid #02a6b5;
  content: "";
}
.mainbox .panel h2 {
  height: 3rem;
  color: #fff;
  line-height: 3rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
}
.mainbox .panel h2 a {
  color: #fff;
  text-decoration: none;
  margin: 0 0.125rem;
}
.mainbox .panel .chart {
  margin-top: 0.5rem;
  height: 15rem;
}
.no {
  background: rgba(101, 132, 226, 0.1);
  padding: 0.8rem;
  margin: 0 0.5rem;
}
.no .no-hd {
  position: relative;
  border: 1px solid rgba(25, 186, 139, 0.17);
}
.no .no-hd::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 30px;
  height: 10px;
  border-top: 2px solid #02a6b5;
  border-left: 2px solid #02a6b5;
}
.no .no-hd::after {
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  width: 30px;
  height: 10px;
  border-right: 2px solid #02a6b5;
  border-bottom: 2px solid #02a6b5;
}
.no .no-hd ul {
  display: flex;
}
.no .no-hd ul li {
  position: relative;
  flex: 1;
  line-height: 5rem;
  font-size: 4.5rem;
  color: #ffeb7b;
  text-align: center;
  font-family: "electronicFont";
}
.no .no-hd ul li::after {
  content: "";
  position: absolute;
  top: 25%;
  right: 0;
  height: 50%;
  width: 1px;
  background: rgba(255, 255, 255, 0.2);
}
.no .no-bd ul {
  display: flex;
}
.no .no-bd ul li {
  flex: 1;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2rem;
  height: 2rem;
  line-height: 2.5rem;
  padding-top: 0.125rem;
}
.map {
  position: relative;
  height: 50.125rem;
}
.map .map1 {
  width: 29.475rem;
  height: 29.475rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(../../../assets/pc/images/large_data_screen/map.png);
  background-size: 100% 100%;
  opacity: 0.3;
}
.map .map2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8.0375rem;
  height: 8.0375rem;
  background: url(../../../assets/pc/images/large_data_screen/lbx.png);
  animation: rotate1 15s linear infinite;
  opacity: 0.6;
  background-size: 100% 100%;
}
.map .map3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32.075rem;
  height: 32.075rem;
  background: url(../../../assets/pc/images/large_data_screen/jt.png);
  animation: rotate2 10s linear infinite;
  opacity: 0.6;
  background-size: 100% 100%;
}
.map .chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 35.125rem;
}
.footer {
  text-align: center;
  color: #fff;
  line-height: 40px;
  font-size: 1rem;
}
@keyframes rotate1 {
  form {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes rotate2 {
  form {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
/* 约束屏幕尺寸 */
/* @media screen and (max-width: 512px) {
  html {
    font-size: 21px !important;
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 80px !important;
  }
} */

</style>
