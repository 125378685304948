// 基准大小
const baseSize = 16;
// 设置 rem 函数
function setRem () {
  // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
  // const scale = document.documentElement.clientWidth / 1920;
  const baseApp = baseSize / 1920;
  let vW = window.innerWidth; // 当前窗口的宽度
  const vH = window.innerHeight; // 当前窗口的高度
  const dueH = vW / 1920;
  if (vH < dueH) {
    // 当前屏幕高度小于应有的屏幕高度，就需要根据当前屏幕高度重新计算屏幕宽度
    vW = vH / 667;
  }
  const rem = vW * baseApp; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应font-size值
  document.documentElement.style.fontSize = rem + 'px'; // 利用DOM去修改值
  // 设置页面根节点字体大小
  // document.documentElement.style.fontSize =
  //   baseSize * Math.min(scale, 2) + "px";
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
};
